import React from "react";
import { graphql, HeadFC } from "gatsby";
import Layout from "../components/layout";
import { Trans } from "react-i18next";
import { HeaderSpacer } from "../components/header";
import AllIngredients from "../components/all_ingredients";
import Heading from "../components/heading";
import { PromoWebshop } from "../components/promo";
import fill from "../images/fill.png";
import illustration from "../images/1_disegno.png";
import SEO from "../seo";

const Ingredients = () => {
  return (
    <Layout className=" scroll-pt-32 snap-proximity">
      <HeaderSpacer className="h-[11rem] snap-start" />
      <div className="mb-12">
        <Heading>Explore Our Ingredients</Heading>
        <p className="p-4 max-w-screen-lg">
          <strong className="font-semibold">
            <Trans>Our extensive network of Apulian</Trans>
          </strong>{" "}
          {/* prettier-ignore */}
          <Trans>farmers and suppliers allows us to provide you with the freshest most authentic Italian food possible. Our homegrown produce, fish, meats, cheeses, and olive oil come straight from Puglia. We visit each farm and supplier to ensure they meet our high-quality standards well before our talented culinarians artfully craft your meal.</Trans>
        </p>
      </div>
      <div
        className="block w-full h-64 relative"
        style={{
          background: `center center / auto 100% url(${fill}) repeat-x`,
        }}
      >
        <img className="h-full mx-auto" src={illustration} />
      </div>
      <AllIngredients />
      <PromoWebshop tight />
    </Layout>
  );
};

export default Ingredients;

export const Head: HeadFC = () => <SEO title="Explore Our Ingredients" />;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
