import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Trans } from "react-i18next";

export const Title = ({ children }: { children: React.ReactNode }) => (
  <h3 className="text-xl text-red-500">
    <span className="bg-white/50">
      <Trans>{children}</Trans>
    </span>
  </h3>
);

const Ingredient = ({
  title,
  paragraph,
  image,
}: {
  title: string;
  paragraph: string;
  image: string;
}) => {
  return (
    <li className="flex flex-col lg:flex-row items-start mb-4 snap-start first:!snap-none">
      <img className="lg:w-2/3" src={image} />
      <div className="lg:w-1/3 -mt-12 lg:mt-0">
        <div
          className={`px-4 h-12 bg-gradient-to-t from-white to-white/50 flex items-center w-full backdrop-sepia`}
        >
          <Title>{title}</Title>
        </div>
        <p className="px-4 lg:text-lg">
          <Trans>{paragraph}</Trans>
        </p>
      </div>
    </li>
  );
};

const AllIngredients = () => {
  const {
    allIngredientsJson: { nodes },
  } = useStaticQuery(query);

  return (
    <ul className="max-w-screen-lg lg:mx-auto">{nodes.map(Ingredient)}</ul>
  );
};

export default AllIngredients;

const query = graphql`
  query IngredientsQuery {
    allIngredientsJson {
      nodes {
        title
        paragraph
        image
      }
    }
  }
`;
